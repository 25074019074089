<template>
  <v-row style="max-width: 800px; margin: 10% auto;">
    <v-col class="text-center">
      <img
        alt="Logo"
        class="shrink mr-2"
        contain
        src="@/assets/tcc-300.png"
        transition="scale-transition"
        width="500"
      >
      <h1>Join</h1>
      <v-card
        class="elevation-5"
        :loading="loading"
        :disabled="loading"
      >
        <v-card-text>
          <h2 :style="{color: msgColor}">
            {{ msgText }}
          </h2>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field

            
              v-model="email"
              label="email"
              name="email"
              prepend-icon="mdi-email"
              type="text"
              autocomplete="on"
              :rules="emailRules"
              @keyup.enter="onJoin"
            />

            <v-text-field
              id="postcode"
              v-model="postcode"
              label="Postcode"
              name="postcode"
              prepend-icon="mdi-home"
              type="text"
              :rules="passRules"
              @keyup.enter="onJoin"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!valid"
            color="primary"
            block
            min-width="100px"
            @click="onJoin"
          >
            Join
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
/* eslint-disable no-debugger */

export default {
    async asyncData( context ) {
        console.log( context );
    },
    data() {
        return {
            msgText: "",
            msgColor: '',
            loading: false,
            valid: true,
            email: "",
            emailRules: [ ( v ) => !!v || "Your Email is required",
                ( v ) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test( v )
            ],
            postcode: "",
            passRules: [
                ( v ) => !!v || "Postcode is required",
                ( v ) => ( v && v.length == 4 ) || "A postcode is 4 characters",
            ],
        };
    },
    computed: {},
    created() {},
    mounted() {
        console.log( "join MOUNTED" );
        console.log( process.env.BASE_URL );
        if( this.email || !this.postcode )this.valid = false;
    },
    methods: {
        validate() {},
        async onJoin( evt ) {
            evt.preventDefault();
            let r = this.$refs.form.validate();
            if ( !r ) return;
            this.loading = true;

            let data = {
                email: this.email,
                loc: this.postcode,
                join: {
                    id: 'web'
                }
            };
            let res = await this.$http.postJSON( 'customers?join=1',data );
            this.loading = false;
            console.log( res );
            if( res && res.ok )return this.msg( "Thank you for joining The Clearance Club, Check your inbox for a welcome email" );
            if( res && !res.ok && res.error == 'USER EXISTS' )return this.msg( "This email is already signed up" );
            this.msg( "Something went wrong!",'red' );
        },
        msg( msg,color ){

            this.msgColor = color || '#333';
            this.msgText = msg;

        }
    },
};
</script>
